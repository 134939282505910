import { icon } from 'leaflet';
import markerImage from './images/marker.png';
import markerRed from './images/marker-red.svg';
import markerBlue from './images/marker-blue.svg';
import markerGreen from './images/marker-green.svg';
import markerPurple from './images/marker-purple.svg';
import markerYellow from './images/marker-yellow.svg';
import markerPoi from './images/marker-poi.svg';
import markerText from './images/marker-text.svg';

/**
 * Custom marker leaflet element.
 */
export const markerIcon = icon({
  iconUrl: markerImage,
  iconSize: [43, 62],
  iconAnchor: [22, 62],
  popupAnchor: [0, 0],
});

export const coloredIcon = (color) => {
  let iconUrl;
  switch (color) {
    case 'blue':
      iconUrl = markerBlue;
      break;
    case 'green':
      iconUrl = markerGreen;
      break;
    case 'purple':
      iconUrl = markerPurple;
      break;
    case 'yellow':
      iconUrl = markerYellow;
      break;
    case 'poi':
      iconUrl = markerPoi;
      break;
    case 'text':
      iconUrl = markerText;
      break;
    default:
      iconUrl = markerRed;
  }

  return icon({
    iconUrl,
    iconSize: [40, 40],
    iconAnchor: [20, 38],
    popupAnchor: [0, 0],
  });
};
